import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/event/:id',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      },
      {
        path: '/changepassword',
        name: 'ChangePassword',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/ChangePassword.vue'),
      },
      {
        path: '/anketalist',
        name: 'AnketaList',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/AnketaList.vue'),
      },
      {
        path: '/createrequest/:id',
        name: 'CreateRequest',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/CreateRequest.vue'),
      },
      {
        path: '/createparticpants/:id',
        name: 'CreateParticpants',
        component: () =>
          import(
            /* webpackChunkName: "login" */ '@/views/CreateParticpants.vue'
          ),
      },
      {
        path: '/viewrequest/:id',
        name: 'RequestView',
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/RequestView.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
