<template>
  <div v-if="apikey == undefined"></div>
  <div v-else>
    <CButton shape="rounded-pill" color="light">{{ fio }}</CButton>
    <CButton shape="rounded-pill" color="success" v-on:click="changepassword()"
      ><CIcon name="cil-lock-locked"
    /></CButton>
    <CButton shape="rounded-pill" color="danger" v-on:click="logout()"
      ><CIcon name="cil-account-logout"
    /></CButton>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      fio: this.$cookies.get('fio'),
      apikey: this.$cookies.get('apikey'),
      secret: this.$cookies.get('secret'),
    }
  },
  mounted() {
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
    axios.defaults.headers.common['ApiKey'] = this.$cookies.get('apikey')
    axios.defaults.headers.common['Secret'] = this.$cookies.get('secret')
    if (this.$cookies.get('apikey') != undefined) {
      axios
        .get(this.$config.API + 'sas/user/extendsession')
        .then((response) => {
          if ((response.data.Error -= undefined)) {
            if (response.data.response == false) {
              this.$cookies.remove('apikey')
              this.$cookies.remove('secret')
              this.$cookies.remove('fio')
              this.$cookies.remove('eventname')
              this.$cookies.remove('eventlogo')
              this.fio = this.$cookies.get('fio')
              this.apikey = this.$cookies.get('apikey')
              this.secret = this.$cookies.get('secret')
              axios.defaults.headers.common['ApiKey'] =
                this.$cookies.get('apikey')
              axios.defaults.headers.common['Secret'] =
                this.$cookies.get('secret')
              location.href = '/'
            } else if (response.data.response == true) {
              this.$cookies.set('apikey', this.$cookies.get('apikey'))
              this.$cookies.set('secret', this.$cookies.get('secret'))
              this.$cookies.set('fio', this.$cookies.get('fio'))
              this.$cookies.set('eventname', this.$cookies.get('eventname'))
              this.$cookies.set('eventlogo', this.$cookies.get('eventlogo'))
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  methods: {
    changepassword: function () {
      this.$router.push({ path: `/changepassword` })
    },
    logout: function () {
      axios
        .get(this.$config.API + 'sas/user/logout')
        .then((response) => {
          if (response.data.Error == undefined) {
            this.$cookies.remove('apikey')
            this.$cookies.remove('secret')
            this.$cookies.remove('fio')
            this.$cookies.remove('eventname')
            this.$cookies.remove('eventlogo')
            this.$cookies.remove('eventid')
            this.fio = this.$cookies.get('fio')
            this.apikey = this.$cookies.get('apikey')
            this.secret = this.$cookies.get('secret')
            axios.defaults.headers.common['ApiKey'] =
              this.$cookies.get('apikey')
            axios.defaults.headers.common['Secret'] =
              this.$cookies.get('secret')
            location.href = '/'
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
