import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import VueCookies from 'vue3-cookies'
import { iconsSet as icons } from '@/assets/icons'

const vueConfig = require('vue-config')
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueCookies, {
  expireTimes: '2d',
  path: '/',
  domain: '',
  secure: false,
  sameSite: 'None',
})

const configs = {
  API: 'https://api.ruvents.com/',
  //API: 'http://localhost:11018/',
  Partner: 'https://partner.admin.ruvents.com/',
  IMG: 'https://galeryphoto.admin.ruvents.com/',
  //GALERY: 'https://photo-eurasia21.ruvents.com/'
}

app.use(vueConfig, configs)

app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
