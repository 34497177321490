<template>
  <CFooter>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by Ruvents</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
