export default [
  {
    component: 'CNavItem',
    name: 'Заявки',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
]
